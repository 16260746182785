/*
 * Nick Lewyn
 * 2021-02-02
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';
import {
  updateStudentTranscriptRequest,
} from '../../../actions';

class EditAddressDialog extends React.Component {
  static propTypes = {
    closeAddressEditDialog: PropTypes.func.isRequired,
    fetchTranscriptRequests: PropTypes.func.isRequired,
    transcriptRequest: PropTypes.object.isRequired,
    updateStudentTranscriptRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      sendToSchoolName: this.props.transcriptRequest.send_to_name,
      sendToContact: this.props.transcriptRequest.send_to_contact,
      sendToAddress: this.props.transcriptRequest.send_to_address,
      sendToAddress2: this.props.transcriptRequest.send_to_address2,
      sendToCity: this.props.transcriptRequest.send_to_city,
      sendToState: this.props.transcriptRequest.send_to_state,
      sendToZip: this.props.transcriptRequest.send_to_zip,
      displayAddressEdit: true,
      displayFieldsHaveNotChangedMessage: false,
    };
  }

  _getAddressValues() {
    return {
      send_to_name: this.state.sendToSchoolName,
      send_to_contact: this.state.sendToContact,
      send_to_address: this.state.sendToAddress,
      send_to_address2: this.state.sendToAddress2,
      send_to_city: this.state.sendToCity,
      send_to_state: this.state.sendToState,
      send_to_zip: this.state.sendToZip,
    };
  }

  _getAddressValuesChanged() {
    const updatedAddress = {};
    Object.keys(this._getAddressValues()).forEach((key) => {
      if (this._getAddressValues()[key] && this._getAddressValues()[key] !== this.props.transcriptRequest[key]) {
        updatedAddress[key] = this._getAddressValues()[key];
      }
    });

    return updatedAddress;
  }

  render() {
    return (
      <Dialog
        open={this.state.displayAddressEdit}
        onClose={() => { this.props.closeAddressEditDialog(); }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-title'>Edit Transcript Address</DialogTitle>
        <DialogContent>
          <TextField
            label='School Name'
            fullWidth={true}
            value={this.state.sendToSchoolName}
            onChange={(e) => this.setState({sendToSchoolName: e.target.value})}
            required={true}
          />
          <TextField
            label='Attn:'
            fullWidth={true}
            value={this.state.sendToContact}
            onChange={(e) => this.setState({sendToContact: e.target.value})}
          />
          <TextField
            label='Address'
            fullWidth={true}
            value={this.state.sendToAddress}
            onChange={(e) => this.setState({sendToAddress: e.target.value})}
            required={true}
          />
          <TextField
            label='Address2'
            fullWidth={true}
            value={this.state.sendToAddress2}
            onChange={(e) => this.setState({sendToAddress2: e.target.value})}
          />
          <TextField
            label='City'
            fullWidth={true}
            value={this.state.sendToCity}
            onChange={(e) => this.setState({sendToCity: e.target.value})}
            required={true}
          />
          <TextField
            label='State'
            fullWidth={true}
            value={this.state.sendToState}
            onChange={(e) => this.setState({sendToState: e.target.value})}
            required={true}
          />
          <TextField
            label='Zip'
            fullWidth={true}
            value={this.state.sendToZip}
            onChange={(e) => this.setState({sendToZip: e.target.value})}
            required={true}
          />
          {this.state.displayFieldsHaveNotChangedMessage &&
            <p style={styles.errorText}>No fields have changed</p>
          }
        </DialogContent>
        <DialogActions>
          <Button
            color='primary' variant='contained' onClick={() => {
              const updateAddress = this._getAddressValuesChanged();
              if (Object.values(updateAddress).length > 0) {
                this.props.updateStudentTranscriptRequest(this.props.transcriptRequest.transcript_request_id, updateAddress);
                this.setState({displayAddressEdit: false});
                this.props.closeAddressEditDialog();
              } else {
                this.setState({displayFieldsHaveNotChangedMessage: true});
              }
            }}
          >
            Update
          </Button>
          <Button color='secondary' onClick={() => { this.props.closeAddressEditDialog(); }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    admin: state.admin,
    student: state.students[props.studentId],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    updateStudentTranscriptRequest,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAddressDialog);
