/*
 * Stevie Michalik
 * 2024-05-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  createInstructorSection,
  fetchBlueprintCoursesByCourseCode,
  fetchCourses,
  fetchInstructors,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import moment from 'moment';
import styles from './styles.js';

const SectionCreation = () => {
  const instructors = useSelector((state) => Object.values(state.instructors).filter((instructor) => instructor.support === 0 && instructor.disabled === 0));
  const blueprints = useSelector((state) => state.blueprints);
  const courses = useSelector((state) => state.courses);
  const fetches = useSelector((state) => state.fetches);

  const [_courseCode, setCourseCode] = useState('biod151');
  const [_blueprint, setBlueprint] = useState('');
  const [_instructor, setInstructor] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstructors());
    dispatch(fetchCourses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBlueprintCoursesByCourseCode(_courseCode));
  }, [dispatch, _courseCode]);

  useEffect(() => {
    const filteredBlueprints = Object.values(blueprints).filter((blueprint) => {
      return (blueprint.course_code === _courseCode);
    });
    if (filteredBlueprints.length > 0) {
      const sortedBlueprints = filteredBlueprints.sort((b1, b2) => {
        return moment(b2.created_at).format('YYYY-MM-DD').date - moment(b1.created_at).format('YYYY-MM-DD').date;
      });
      setBlueprint(sortedBlueprints[sortedBlueprints.length - 1].canvas_course_id);
    }
  }, [dispatch, fetches]);

  const _returnSelectCourseOptions = () => {
    const filteredCourses = Object.values(courses).filter((course) => {
      return (course.disabled === 0 && !course.code.includes('-'));
    });

    const sortedCourses = filteredCourses.sort((c1, c2) => {
      return (c1.code > c2.code) ? 1 : (c1.code < c2.code) ? -1 : 0;
    });

    return sortedCourses.map((course) => {
      return <MenuItem key={course.id} value={course.code}>{course.code}</MenuItem>;
    });
  };

  const _returnSelectBlueprintOptions = () => {
    const sortedBlueprints = Object.values(blueprints).sort((b1, b2) => {
      return (b1.course_code > b2.course_code) ? 1 : (b1.course_code < b2.course_code) ? -1 : 0;
    });

    const filteredBlueprints = sortedBlueprints.filter((blueprint) => {
      return (blueprint.course_code === _courseCode);
    });

    return filteredBlueprints.map((blueprint) => {
      return <MenuItem key={blueprint.canvas_course_id} value={blueprint.canvas_course_id}>{blueprint.name}</MenuItem>;
    });
  };

  return (
    <div style={styles.container}>
      <Paper style={styles.paper} elevation={8}>
        <div>
          <h2>Create new Section for Instructor</h2>
          <p>This feature is designed to create a brand new section for an instructor based on a blueprint</p>
          <p>Use when adding an instructor to a course they have never taught before, or when adding an instructor to a new blueprint</p>
          <p>The feature will default to select the Blueprint that has been most recently created.</p>
        </div>
        <div style={styles.selectContainer}>
          <InputLabel>Course Code</InputLabel>
          <Select
            defaultValue={_courseCode}
            id='courseCodeSelect'
            label='Type'
            onChange={(e) => {
              setCourseCode(e.target.value);
            }}
          >
            {_returnSelectCourseOptions()}
          </Select>
        </div>
        <div style={styles.selectContainer}>
          <InputLabel>Blueprint</InputLabel>
          <Select
            defaultValue={_blueprint}
            key={_blueprint}
            id='blueprintSelect'
            label='Type'
            onChange={(e) => {
              setBlueprint(e.target.value);
            }}
          >
            {_returnSelectBlueprintOptions()}
          </Select>
        </div>
        <div style={styles.selectContainer}>
          <InputLabel>Instructor</InputLabel>
          <Select
            defaultValue={_instructor}
            id='instructorSelect'
            label='Type'
            onChange={(e) => {
              setInstructor(e.target.value);
            }}
          >
            {instructors.length > 0 && Object.values(instructors).sort((i1, i2) => {
              return (i1.first_name > i2.first_name) ? 1 : (i1.first_name < i2.first_name) ? -1 : 0;
            }).map((instructor) => {
              return <MenuItem key={instructor.id} value={instructor.id}>{instructor.first_name} {instructor.last_name}</MenuItem>;
            })}
          </Select>
        </div>
        <div>
          <Button
            onClick={() => {
              dispatch(createInstructorSection(_courseCode, _blueprint, _instructor));
            }}
            disabled={!_instructor || !_blueprint || !_courseCode}
            variant='contained'
            color='primary'
          >
          Create Instructor Section
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default SectionCreation;
