/*
 * Nathaniel Padgett
 * 2021-07-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import PropTypes from 'prop-types';
import React from 'react';
import {
  TableCell,
} from '@material-ui/core';
import {
  formatNumberAsUScurrency,
} from '../../../utilities';

const EditableTableCell = (props) => {
  const [_currentValue, setCurrentValue] = React.useState(props.value);
  const [_editableMode, setEditableMode] = React.useState(false);
  const textInput = React.createRef();

  const updateValueIfChanged = () => {
    setEditableMode(false);
    if (props.value !== _currentValue.trim()) {
      props.updateValue(_currentValue);
    }
  };

  React.useEffect(() => {
    textInput.current?.focus();
  });

  if (!_editableMode) {
    return (
      <TableCell
        onDoubleClick={() => {
          setEditableMode(true);
        }}
      >{props.formatAsCurrency ? formatNumberAsUScurrency(props.value) : props.value}</TableCell>
    );
  }
  return (
    <TableCell
      onKeyUp={(e) => {
        if (['Escape', 'Enter'].includes(e.key)) {
          updateValueIfChanged();
        }
      }}
    >
      <input
        type='text'
        value={_currentValue}
        ref={textInput}
        onChange={(e) => {
          setCurrentValue(e.target.value);
        }}
        onBlur={updateValueIfChanged}
      ></input>
    </TableCell>
  );
};

EditableTableCell.propTypes = {
  updateValue: PropTypes.func.isRequired,
  formatAsCurrency: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default EditableTableCell;
