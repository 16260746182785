/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
} from '@material-ui/core';
import {
  createNewConfirmedSchool,
  fetchKnownSchools,
  fetchUnknownSchools,
  fixUnknownSchool,
  removeKnownSchool,
  updateSchool,
} from '../../actions';
import EditableTableCell from '../Refunds/EditableTableCell';
import PropTypes from 'prop-types';
import React from 'react';
import UnknownSchoolCard from './UnknownSchoolCard';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import queryString from 'query-string';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const options = [];
for (let i = 0; i < 10000; i = i + 1) {
  options.push({value: i, label: `Option ${i}`});
}

class SchoolNames extends React.Component {
  static propTypes = {
    createNewConfirmedSchool: PropTypes.func.isRequired,
    updateSchool: PropTypes.func.isRequired,
    fetchKnownSchools: PropTypes.func.isRequired,
    fetchUnknownSchools: PropTypes.func.isRequired,
    fixUnknownSchool: PropTypes.func.isRequired,
    knownSchools: PropTypes.array.isRequired,
    unknownSchools: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      newSchoolDialogOpen: false,
      newSchoolNameText: '',
      otherSuggestions: {},
      searchText: '',
      rowsPerPage: 10,
      pageNum: 0,
      sortKey: 'name',
      sortAsc: true,
    };
  }

  componentDidMount() {
    this.props.fetchKnownSchools();
    this.props.fetchUnknownSchools(queryString.parse(this.props.location.search, {ignoreQueryPrefix: true}).max_student_id || 100000);
  }

  _getSchoolColor(jaroWinklerScore) {
    const normalizedScore = (jaroWinklerScore - 0.8) / (1 - 0.8); // @TODO: magic
    const hue = (normalizedScore * 120).toString(10);

    return `hsl(${hue},100%,50%)`;
  }

  // @TODO: move somewhere...
  // @TODO: first step is to trim trailing whitespace
  // @TODO: if close and confident, return green with original name on hover
  // @TODO: if close and not confident, return yellow with original name next to option(s)
  // @TODO: if not close (and therefore not confident), return red with original name and options
  // @TODO: options should include whitelisting and creating new mapping (e.g. UD always expands to University of Delaware [note: this is a bad example because of University of Dayton, etc.])
  // @TODO: have tips for admins like "check to see whether this schools is *The* University of XYZ or just University of XYZ

  render() {
    const searchTerms = this.state.searchText.split(' ').map((text) => text.toLowerCase());
    const filteredData = this.props.knownSchools.filter((school) => {
      return searchTerms.every((searchTerm) => {
        return Object.keys(school).some((fileAttribute) => {
          return `${school[fileAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredData.length - this.state.pageNum * this.state.rowsPerPage);
    return (
      <div style={styles.container}>
        {/* @TODO: tips section text:
          Articles and prepositions (the, of, at, etc.) are never capitalized unless one is the first word in the name of the instituition.
          "The" is included as the first word in the title only if the instituition officially includes it in its name.
          No words are ever abbreviated (e.g. "St." is always "Saint").
          branch campuses? (at Beaver) vs. -- Beaver vs. something else
        */}
        {/* @TODO: npm module for finding unused styles? */}
        <div style={styles.unknownSchools}>
          <h3>Unknown Schools</h3>
          {/* @TODO: hide instructions behind tooltips and tutorials */}
          <p>{'Clicking "ADD SCHOOL" will mark this as a known school.  This means that this is the first registration we have from that university (or high school).'}</p>
          <p>{'Clicking "REMOVE SCHOOL" will delete this entry.  This button exists only for spam, not for schools that are misspelled, etc.'}</p>
          <p>{'There are two checkmarks for each suggestion.  The first will update the existing school name to match the suggestion.  The second one will update the school name to match the suggestion and then will also create a rule to always convert that input to the suggestion.  For example, if "Geenva" is not automatically converted to "Geneva", you can convert it manually this one time using the first button, or you can tell the system to convert "Geenva" to "Geneva" this time and every time "Geenva" is entered in the future as well.  In general, the more you use the second option, the smarter the system gets, and manual corrections will be required less often.  However, sometimes this can be a bad idea.  For example, "Northestern" might be typed by two students from "Northwestern" and "Northeastern".  While this may be able to be corrected on a per case basis using additional information such as the school address, it\'s not a correction that should be automatically applied for all "Northestern" inputs in the future.'}</p>
          <Grid
            container={true}
            spacing={2}
          >
            {/* @TODO: convert list to cards */}
            {this.props.unknownSchools.map((school) => {
              return (
                <UnknownSchoolCard
                  key={school.id}
                  school={school}
                  knownSchools={this.props.knownSchools}
                />
              );
            })}
          </Grid>
        </div>
        <div style={styles.knownSchools}>
          <h3>Known Schools</h3>
          <Paper style={styles.tableWrapper}>
            <Toolbar style={styles.tableToolbar}>
              <div style={styles.leftItems}>
                {/* @TODO: search bar...needs to work on all fields, not just visible ones. but if not visible, need to show waht it's matching against...also have specific search bars for each column */}
                <TextField
                  style={styles.textField}
                  label='Search'
                  value={this.state.searchText}
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  margin='dense'
                />
              </div>
            </Toolbar>
            <div>
              <Table aria-labelledby='tableTitle'>
                {/* @TODO: make columns sortable? */}
                <TableHead>
                  <TableRow>
                    <TableCell>School Name</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.sort((a, b) => {
                    let valueOfA = a[this.state.sortKey];
                    let valueOfB = b[this.state.sortKey];
                    if (typeof valueOfA === 'string') {
                      valueOfA = valueOfA.toLowerCase().trim();
                      valueOfB = valueOfB.toLowerCase().trim();
                    }
                    if (this.state.sortAsc) {
                      return valueOfA > valueOfB ? 1 : -1;
                    }
                    return valueOfA < valueOfB ? 1 : -1;
                  }).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).sort().map((school) => {
                    return (
                      <React.Fragment key={school.id}>
                        <TableRow
                          hover
                          tabIndex={-1}
                        >
                          <EditableTableCell
                            updateValue={(value) => {
                              this.props.createNewConfirmedSchool(
                                value,
                                school.city,
                                school.state,
                                true,
                                school.id,
                                true,
                                () => {
                                  this.props.updateSchool(school.id, {
                                    confirmed: 0,
                                  });
                                }
                              );
                            }}
                            formatAsCurrency={false}
                            value={school.name}
                          />
                          <TableCell>{school.city}</TableCell>
                          <TableCell>{school.state}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {/* @TODO: update pagination props */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component='div'
              count={filteredData.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.pageNum}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onPageChange={(event, pageNum) => {
                // @TODO: update query params
                this.setState({pageNum});
              }}
              onRowsPerPageChange={(event) => {
                this.setState({rowsPerPage: event.target.value});
              }}
            />
          </Paper>
        </div>
        <Menu
          id='simple-menu'
          anchorEl={this.state.anchorEl}
          open={!!this.state.anchorEl}
          onClose={() => this.closeMenu()}
        >
          <MenuItem
            onClick={() => {
              // @TODO: open dialog with more info
              this.closeMenu();
            }}
          >
            More info
          </MenuItem>
          <MenuItem
            onClick={() => {
              // @TODO: open dialog with delete options
              /* @TODO: confirm admin doesn't want to fix school */
              this.closeMenu();
            }}
          >
            Remove school
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    knownSchools: Object.values(state.knownSchools),
    unknownSchools: Object.values(state.unknownSchools),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchKnownSchools,
    fetchUnknownSchools,
    fixUnknownSchool,
    createNewConfirmedSchool,
    updateSchool,
    removeKnownSchool,
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolNames));
