/*
 * Ryan O'Dowd
 * 2020-11-17
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  fetchStudent,
  getAllCampuses,
  updateStudent,
} from '../../../actions';
import {
  EDIT_SCHOOL_INFO,
} from '../../../Globals';
import EditIcon from '@material-ui/icons/Edit';
import KnownSchoolsTextField from '../../SchoolNames/KnownSchoolsTextField';
import {
  Link,
} from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import React from 'react';
import ResetUserPassword from '../../Shared/ResetPassword';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import moment from 'moment';
import provinces from 'provinces';
import styles from './styles';

const SELECT_INITIAL_VALUE = 'none';
const PROVINCE_COUNTRY_US = 'US';
const US_STATES = provinces.filter((value) => value.country === PROVINCE_COUNTRY_US);

class EditStudentDialog extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    getAllCampuses: PropTypes.func.isRequired,
    fetchResetUserPassword: PropTypes.func.isRequired,
    fetchStudent: PropTypes.func.isRequired,
    institutionCampuses: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    studentId: PropTypes.number.isRequired,
    updateStudent: PropTypes.func.isRequired,

    student: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      editStudentDialogOpen: false,

      errorNoNotesText: false,
      errorNoChangeOnUpdate: false,

      displayActiveStudentsOnly: true,
      displayUnassignedStudentsOnly: false,

      // controlled inputs
      searchText: '',
      firstNameText: '',
      middleNameText: '',
      lastNameText: '',
      birthdayText: '',
      emailText: '',
      phoneText: '',
      streetAddressText: '',
      streetAddressLineTwoText: '',
      cityText: '',
      stateOrProvinceOption: SELECT_INITIAL_VALUE,
      zipText: '',
      countryOption: SELECT_INITIAL_VALUE,
      schoolNameText: '',
      newSchoolName: '',
      schoolCityText: '',
      schoolStateOrProvinceOption: SELECT_INITIAL_VALUE,
      studentIsDisabled: '',
      notesText: '',
      isStudentMilitary: '',

      isInternationalAddress: false,
    };
  }

  componentDidMount() {
    this.props.fetchStudent(this.props.studentId);
    this.props.getAllCampuses();
  }

  _formatBirthday(backendFormat) {
    const birthday = new Date(backendFormat);
    return `${birthday.getUTCFullYear()}-${birthday.getUTCMonth() + 1}-${birthday.getUTCDate()}`;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.student !== this.props.student) {
      let birthdayText = '';
      if (this.props.student.birthday) {
        birthdayText = this._formatBirthday(this.props.student.birthday);
      }
      this.setState({
        firstNameText: this.props.student.first_name || '',
        middleNameText: this.props.student.middle_name || '',
        lastNameText: this.props.student.last_name || '',
        birthdayText,
        emailText: this.props.student.email || '',
        phoneText: (this.props.student.phone && this.props.student.phone.replace(/\D/g, '')) || '',
        streetAddressText: this.props.student.line_1 || '',
        streetAddressLineTwoText: this.props.student.line_2 || '',
        cityText: this.props.student.city || '',
        stateOrProvinceOption: this.props.student.state || '',
        zipText: this.props.student.zip_code || '',
        countryOption: this.props.student.country || '',
        studentIsDisabled: this.props.student.disabled || '',
        notesText: '',
        schoolNameText: this.props.student.school || '',
        schoolCityText: this.props.student.school_city || '',
        schoolStateOrProvinceOption: this.props.student.school_state || '',
        isBirthdayDatePickerOpen: false,
        isStudentMilitary: this.props.student.military,
      });
    }
  }

  _isCorrectPhoneLength(phone) {
    return phone.length >= 10;
  }

  render() {
    if (!this.props.student) {
      return <CircularProgress color='secondary' />;
    }

    return (
      <Dialog
        open={true}
        onClose={() => this.props.onClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Edit student info
          <DialogActions>
            <ResetUserPassword userId={this.props.student.id} firstName={this.props.student.first_name} lastName={this.props.student.last_name} />
          </DialogActions>
        </DialogTitle>
        <DialogContent style={styles.innerDialogContent}>
          <div style={styles.form}>{/* @TODO: see website repo */}
            <div style={styles.formRow}>{/* @TODO: see website repo */}
              <TextField
                style={styles.textField}
                label='First name'
                value={this.state.firstNameText}
                onChange={(e) => this.setState({firstNameText: e.target.value})}
                margin='dense'
                required={true}
              />
              <TextField
                style={styles.textField}
                label='Middle name'
                value={this.state.middleNameText}
                onChange={(e) => this.setState({middleNameText: e.target.value})}
                margin='dense'
              />
              <TextField
                style={styles.textField}
                label='Last name'
                value={this.state.lastNameText}
                onChange={(e) => this.setState({lastNameText: e.target.value})}
                margin='dense'
                required={true}
              />
            </div>
            <div style={styles.formRow}>{/* @TODO: see website repo */}
              <TextField
                style={{...styles.textField, width: '48%'}}
                label='Email address'
                type='email'
                value={this.state.emailText}
                onChange={(e) => this.setState({emailText: e.target.value.toLowerCase()})}
                margin='dense'
                required={true}
              />
              {/* @TODO: replace this with the lib from the sign-up form on the website */}
              <TextField
                style={{...styles.textField, width: '48%'}}
                label='Phone number'
                type='tel'
                error={!this._isCorrectPhoneLength(this.state.phoneText)}
                value={this.state.phoneText}
                onChange={(e) => this.setState({phoneText: e.target.value})}
                margin='dense'
                helperText='Please enter a 10-digit phone number unless international student'
                required={true}
              />
            </div>
            <div style={styles.formRow}>
              <TextField
                style={{...styles.textField, width: '35%'}}
                label='Birthday'
                disabled={true}
                type='tel'
                value={moment(this.state.birthdayText, 'YYYY-MM-DD').format('L')}
                onChange={(e) => this.setState({phoneText: e.target.value})}
                margin='dense'
                required={true}
              />
              <div style={styles.deadlineWrapper}>
                <IconButton onClick={() => this.setState({isBirthdayDatePickerOpen: true})}>
                  <EditIcon />
                </IconButton>
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isStudentMilitary}
                    onChange={(e) => this.setState({isStudentMilitary: e.target.checked})}
                    required={false}
                  />
                }
                label='Military'
              />
              {this.state.isBirthdayDatePickerOpen &&
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    open={true}
                    value={moment(this.state.birthdayText)}
                    style={styles.datePicker}
                    onChange={(e, value) => {
                      this.setState({birthdayText: e.format('YYYY-MM-DD')});
                    }}
                    onClose={() => this.setState({isBirthdayDatePickerOpen: false})}
                  />
                </MuiPickersUtilsProvider>
              }
            </div>
            <div style={styles.formRow}>
              <div>
                <Checkbox
                  value={this.state.isInternationalAddress}
                  onChange={(e) => {
                    this.setState({isInternationalAddress: e.target.checked});
                  }}
                  required={false}
                />
                Make this an international address
              </div>
            </div>
            <div style={styles.formRow}>
              <TextField
                style={{...styles.textField, width: '100%'}}
                label='Address'
                value={this.state.streetAddressText}
                onChange={(e) => this.setState({streetAddressText: e.target.value})}
                margin='dense'
                required={true}
              />
            </div>
            <div style={styles.formRow}>
              <TextField
                style={{...styles.textField, width: '100%'}}
                label='Address line 2 (optional)'
                value={this.state.streetAddressLineTwoText}
                onChange={(e) => this.setState({streetAddressLineTwoText: e.target.value})}
                margin='dense'
              />
            </div>
            {!this.state.isInternationalAddress &&
              <>
                {/* @TODO:
                <div style={styles.formRow}>
                  <FormControl style={styles.formRow}>
                    <InputLabel htmlFor='country'></InputLabel>
                    <Select
                      style={styles.countrySelectField}
                      value={this.state.countryOption}
                      onChange={e => this.setState({countryOption: e.target.value})}
                      input={<Input id='country' name='country' style={styles.textField} />}
                    >
                      {/* @TODO: make usa default /}
                      {/* @TODO: abstract names/values /}
                      {[
                        <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>Country*</MenuItem>,
                        ...[{name: 'United States', value: 'usa'}, {name: 'Canada', value: 'canada'}].map(({name, value}) => <MenuItem key={name} value={value}>{name}</MenuItem>),
                      ]}
                    </Select>
                    {/* @TODO: inputlabel should stay above input when not null /}
                    {/* @TODO: only show state/province dropdown if country is selected /}
                  </FormControl>
                </div>
                    */}
                <div style={styles.formRow}>
                  <TextField
                    style={styles.textField}
                    label='City'
                    value={this.state.cityText}
                    onChange={(e) => this.setState({cityText: e.target.value})}
                    margin='dense'
                    required={true}
                  />
                  <FormControl>
                    <InputLabel htmlFor='state'></InputLabel>
                    <Select
                      style={styles.stateSelectField}
                      value={this.state.stateOrProvinceOption}
                      onChange={(e) => this.setState({stateOrProvinceOption: e.target.value})}
                      input={<Input id='state' name='state' style={styles.registerInputField} />}
                    >
                      {/* @TODO: add provinces for canada */}
                      {[
                        <MenuItem key='placeholder' value={SELECT_INITIAL_VALUE} disabled={true}>State*</MenuItem>,
                        US_STATES.map((stateOrProvince) => <MenuItem key={stateOrProvince.name} value={stateOrProvince.short}>{stateOrProvince.short}</MenuItem>),
                      ]}
                    </Select>
                  </FormControl>
                  <TextField
                    style={styles.textField}
                    label='ZIP code'
                    type='tel'
                    value={this.state.zipText}
                    onChange={(e) => this.setState({zipText: e.target.value})}
                    margin='dense'
                    required={true}
                  />
                </div>
              </>
            }
            {!!this.props.admin.perms[EDIT_SCHOOL_INFO] &&
              <>
                <div style={styles.formRow}>
                  <p style={styles.noMarginBottom}>{this.props.student.school}<br /><span style={styles.subtitle}>{`${this.props.student.school_city}, ${this.props.student.school_state}`}</span></p>
                </div>
                <div style={styles.formRow}>
                  <div style={styles.expand}>
                    <KnownSchoolsTextField
                      onChange={(e, value) => {
                        this.setState({newSchoolName: `${value.name} - ${value.city}, ${value.state}`});
                      }}
                      onInputChange={(e, value) => {
                        if (e) {
                          this.setState({newSchoolName: e.target.value});
                        }
                      }}
                      schoolNameInput={this.state.newSchoolName}
                      noOptionsText='No Institution Campus'
                      inputLabel='New Institution Campus'
                    />
                  </div>
                </div>
              </>
            }
            <div style={styles.formRow}>
              <Tooltip title='Please specify the reason for this student change' placement='bottom-start'>
                <TextField
                  style={{...styles.textField, width: '100%'}}
                  label='Reason'
                  error={this.state.errorNoNotesText}
                  placeholder='Reason, proof provided, etc.'
                  value={this.state.notesText}
                  onChange={(e) => this.setState({notesText: e.target.value, errorNoNotesText: false})}
                  margin='dense'
                  required={true}
                />
              </Tooltip>
            </div>
          </div>
          {this.state.errorNoChangeOnUpdate && <p style={styles.errorText}>No fields have changed for the student.</p>}
          {/* @TODO: see website repo for error handling and form validation */}
        </DialogContent>
        <DialogActions>
          <span style={styles.expand}></span>
          <Button
            onClick={() => {
              const MINIMAL_FIELDS_ALWAYS_SENT = 2;
              // @TODO: form validation
              if (!this.state.notesText || this.state.notesText.trim().length === 0) {
                this.setState({errorNoNotesText: true});
                return;
              }
              if (!this._isCorrectPhoneLength(this.state.phoneText)) {
                return;
              }
              const updatedStudent = {id: this.props.student.id};
              if (this.state.firstNameText && this.state.firstNameText !== this.props.student.first_name) {
                updatedStudent.first_name = this.state.firstNameText;
              }
              if (this.state.middleNameText !== this.props.student.middle_name) {
                updatedStudent.middle_name = this.state.middleNameText;
              }
              if (this.state.lastNameText && this.state.lastNameText !== this.props.student.last_name) {
                updatedStudent.last_name = this.state.lastNameText;
              }
              if (this.state.emailText && this.state.emailText !== this.props.student.email) {
                updatedStudent.email = this.state.emailText;
              }
              if (this.state.phoneText && this.state.phoneText !== this.props.student.phone) {
                updatedStudent.phone = this.state.phoneText;
              }
              if (this.state.birthdayText && this.state.birthdayText !== this._formatBirthday(this.props.student.birthday)) {
                updatedStudent.birthday = this.state.birthdayText;
              }
              if (this.state.streetAddressText && this.state.streetAddressText !== this.props.student.line_1) {
                updatedStudent.address = this.state.streetAddressText;
              }
              if (this.state.streetAddressLineTwoText !== this.props.student.line_2) {
                updatedStudent.address2 = this.state.streetAddressLineTwoText;
              }
              if (this.state.cityText && this.state.cityText !== this.props.student.city) {
                updatedStudent.city = this.state.cityText;
              }
              if (this.state.stateOrProvinceOption && this.state.stateOrProvinceOption !== this.props.student.state) {
                updatedStudent.state = this.state.stateOrProvinceOption;
              }
              if (this.state.zipText && this.state.zipText !== this.props.student.zip_code) {
                updatedStudent.zip = this.state.zipText;
              }
              if (this.state.isStudentMilitary !== this.props.student.military) {
                updatedStudent.military = this.state.isStudentMilitary;
              }
              if (this.state.isInternationalAddress) {
                if (this.props.student.line_2 !== '') {
                  updatedStudent.address2 = '';
                }
                if (this.props.student.city !== '') {
                  updatedStudent.city = '';
                }
                if (this.props.student.state !== '') {
                  updatedStudent.state = '';
                }
                if (this.props.student.zip_code !== '') {
                  updatedStudent.zip = '';
                }
                if (this.props.student.country !== '') {
                  updatedStudent.country = '';
                }
              } else {
                if (this.props.student.country === '') {
                  updatedStudent.country = 'United States';
                }
              }
              // @TODO use school ids instead of formatted school name, city, state
              const institutionCampus = this.state.newSchoolName && Object.values(this.props.institutionCampuses).find((school) => this.state.newSchoolName.trim() === `${school.name} - ${school.city}, ${school.state}`);
              if (this.state.newSchoolName && institutionCampus && institutionCampus.id !== this.props.student.institution_campus_id) {
                updatedStudent.institution_campus_id = institutionCampus.id;
              }
              updatedStudent.notes = this.state.notesText;
              if (Object.keys(updatedStudent).length <= MINIMAL_FIELDS_ALWAYS_SENT) {
                this.setState({errorNoChangeOnUpdate: true});
                return;
              }
              this.props.updateStudent(updatedStudent);
              this.props.onClose();
            }}
            color='primary'
            variant='contained'
          >
            Update
          </Button>
          <Button
            variant='contained'
            color='primary'
            component={Link}
            to={`/students/merge?initial_source_student_id=${this.props.student.id}`}
          >
            Merge
          </Button>
          <Button
            onClick={() => this.props.onClose()}
            color='secondary'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    admin: state.admin,
    student: state.students[props.studentId],
    institutionCampuses: state.allCampuses,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchStudent,
    updateStudent,
    getAllCampuses,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditStudentDialog);
