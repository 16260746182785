/*
 * Nick Lewyn
 * 2020-02-14
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  completedCourseTableDialogSection: {
    marginBottom: 25,
  },
  completedTranscriptTableColor: {
    backgroundColor: Globals.colors.lightGray,
    color: Globals.colors.white,
  },
  electronicNotAvailable: {
    backgroundColor: `${Globals.colors.transcripts.electronicNotAvailable}`,
  },
  tableBorderUnCompleteStyle: {
    borderLeft: `6px solid ${Globals.colors.transcripts.uncompleted}`,
  },
  tableBorderCompleteStyle: {
    borderLeft: `6px solid ${Globals.colors.transcripts.completed}`,
  },
  tableBorderInvalidStyle: {
    borderLeft: `6px solid ${Globals.colors.transcripts.invalid}`,
  },
  highlightedText: {
    color: Globals.colors.light,
    backgroundColor: Globals.colors.accent,
  },
  filterBySelectField: {
    width: 208,
    marginLeft: 8,
    marginTop: 21, // @TODO: magic so the bottom lines up with neighboring inputs
  },
  filterArea: {
    display: 'flex',
    marginBottom: 20,
  },
  completedFilterArea: {
    marginLeft: 'auto',
  },
  completedFilterAreaModified: {
    marginLeft: 'auto',
    marginTop: 20,
  },
  filterSearchButton: {
    marginLeft: 10,
  },
  searchTextField: {
    marginTop: 24,
    marginLeft: 10,
  },
  copyButton: {
    marginLeft: 12,
  },
  processButton: {
    marginBottom: 12,
  },
  markInvalid: {
    marginBottom: 12,
  },
  lastRefreshDate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorParagraph: {
    color: Globals.colors.error,
  },
  addressEditButton: {
    marginLeft: 12,
  },
  uploadFileName: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
  },
  editEmailTextField: {
    width: 280,
  },
  transcriptTypeFilterDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterByTranscriptDDL: {
    width: 110,
    marginLeft: 8,
    marginTop: 21,
  },
  filterByTranscriptLabel: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  buttonSearchDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  dateSearchPickerUtil: {
    paddingRight: 10,
    paddingTop: 5,
    paddingLeft: 2,
  },
  datePicker: {
    paddingTop: 5,
  },
};

export default styles;
