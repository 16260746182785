/*
 * Nick Lewyn & Andrew Moskal
 * 2024-01-23
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React, {
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  fetchResetUserPassword,
} from '../../../actions';
import {
  useDispatch,
} from 'react-redux';

const ResetUserPassword = (props) => {
  const [_buttonResetPasswordDisabled, setButtonResetPasswordDisabled] = useState(false);
  const [_openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        disabled={_buttonResetPasswordDisabled}
        onClick={() => setOpenDialog(true)}
      >
      Reset Password
      </Button>
      <Dialog
        open={_openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Reset Password</DialogTitle>
        <DialogContent>
          <div>
            {(props.firstName && props.lastName &&
            <p>An email will be sent to {props.firstName + ' ' + props.lastName} with their temporary password.</p>) ||
              (props.schoolName &&
            <p>An email will be sent to {props.schoolName} with the temporary password</p>) ||
            <p>An email will be sent to the user with their temporary password.</p>}
            <p>{"Are you sure you want to reset this user's password?"}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              dispatch(fetchResetUserPassword(props.userId, props.requestType));
              setButtonResetPasswordDisabled(true);
              setOpenDialog(false);
            }}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ResetUserPassword.propTypes = {
  userId: PropTypes.number.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  schoolName: PropTypes.string,
  requestType: PropTypes.oneOf(['', 'etts', 'admin']),
};

ResetUserPassword.defaultProps = {
  requestType: '',
};
export default ResetUserPassword;
