
/*
 * Rob DePietro
 * 2024-09-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import Globals, {
  DEVELOPER,
} from '../../../../Globals';
import React, {
  useState,
} from 'react';
import {
  getFormattedPhone,
  isLessThan18YearsAgo,
} from '../../../../utilities';
import CakeIcon from 'mdi-react/CakeIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import HighlightOffIcon from 'mdi-react/HighlightOffIcon';
import PropTypes from 'prop-types';
import RestoreClockIcon from 'mdi-react/RestoreClockIcon';

import moment from 'moment';
import styles from '../styles';
import {
  useSelector,
} from 'react-redux';
import {
  withRouter,
} from 'react-router-dom';

const StudentInformationDetails = (props) => {
  const [_showMoreDetails, setShowMoreDetails] = useState(false);
  const [_showNotes, setShowNotes] = useState(false);
  const admin = useSelector((state) => state.admin);
  const studentsPreviousRecords = useSelector((state) => state.studentsPreviousRecords);
  const PRETTY_DATE_FORMAT = 'MMM D, YYYY';

  const _renderField = (label, content, action) => {
    return (
      <div style={styles.formFieldWrapper}>
        <h6 style={styles.formFieldLabel}>{label}</h6>
        <div style={styles.formFieldContent}>{content} {action}</div>
      </div>
    );
  };

  const _formatName = (firstName, middleName, lastName) => {
    return `${firstName} ${middleName}${middleName ? ' ' : ''}${lastName}`;
  };

  const _formatAddress = (streetAddress, addressLineTwo, city, state, zip, country) => {
    return (
      <span>
        {streetAddress}<br />
        {addressLineTwo && (
          <>
            {addressLineTwo}<br />
          </>
        )}
        {(!!city && !!state) &&
            <>{`${city}, ${state} ${zip}`}<br /></>
        }
        {country}
      </span>
    );
  };

  const _formatSchoolOfIntent = (name, city, state, program) => {
    return (
      <span>
        {name}<br />
        {city}
        {(city && state) && ', '}
        {state}
        {(city || state) && program && <br />}
        {program}
      </span>
    );
  };

  const _renderPreviousInfo = (previousRecords) => {
    return (
      <div>
        <IconButton
          style={styles.previousRecordsIconWrapper}
          color='secondary'
          onClick={(event) => {
            setShowNotes(!_showNotes);
          }}
        >
          <RestoreClockIcon size={18} />
        </IconButton>
        {previousRecords.map((previousRecord) => (
          _showNotes &&
            <Tooltip
              key={previousRecord.id}
              title={
                <>
                  <p>Deprecated <strong>{previousRecord.inserted_at}</strong> by <strong>{previousRecord.changed_by}</strong>.</p>
                  <p>Notes: <strong>{previousRecord.notes}</strong></p>
                </>
              }
            >
              <div>
                <span style={styles.tooltipTrigger}>
                  {previousRecord.data}
                </span>
              </div>
            </Tooltip>
        ))}
      </div>
    );
  };

  const _getPreviousInfoByGroup = () => {
    const previousNames = [];
    const previousAddresses = [];
    const previousEmails = [];
    const previousPhoneNumbers = [];
    const previousSchoolOfIntent = [];
    const previousNotes = [];

    const snapshotStudent = {...props.student}; // this is used to track all student fields at each change (because previous records contain only the diff between changes...this will have all attributes)

    (studentsPreviousRecords[props.student.id] || []).forEach((previousRecord) => {
      Object.keys(previousRecord).forEach((attribute) => {
        if (previousRecord[attribute]) {
          snapshotStudent[attribute] = previousRecord[attribute];
        }
      });
      const currItem = {
        id: previousRecord.id,
        notes: previousRecord.notes,
        inserted_at: moment(previousRecord.inserted_at).format('L LT'),
        changed_by: previousRecord.changed_by,
      };
      if (previousRecord.first_name || previousRecord.middle_name || previousRecord.last_name) {
        previousNames.push({
          ...currItem,
          data: _formatName(snapshotStudent.first_name, snapshotStudent.middle_name, snapshotStudent.last_name),
        });
      }
      if (previousRecord.address_id) {
        previousAddresses.push({
          ...currItem,
          data: _formatAddress(
            previousRecord.address.line_1,
            previousRecord.address.line_2,
            previousRecord.address.city,
            previousRecord.address.state,
            previousRecord.address.zip_code,
            previousRecord.address.country,
          ),
        });
      }
      if (previousRecord.email) {
        previousEmails.push({
          ...currItem,
          data: previousRecord.email,
        });
      }
      if (previousRecord.phone) {
        previousPhoneNumbers.push({
          ...currItem,
          data: getFormattedPhone(previousRecord.phone),
        });
      }
      if (previousRecord.school_of_intent_id) {
        previousSchoolOfIntent.push({
          ...currItem,
          data: _formatSchoolOfIntent(
            previousRecord.school_of_intent.name,
            previousRecord.school_of_intent.city,
            previousRecord.school_of_intent.state,
            previousRecord.school_of_intent.program,
          ),
        });
      }
      if (previousRecord.notes) {
        previousNotes.push({
          ...currItem,
          data: previousRecord.notes,
        });
      }
    });

    return {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    };
  };

  const _renderStudentInfo = () => {
    const {
      previousNames,
      previousAddresses,
      previousEmails,
      previousPhoneNumbers,
      previousSchoolOfIntent,
      previousNotes,
    } = _getPreviousInfoByGroup(props.student);

    return (
      <div style={styles.detailsWrapper}>
        <span style={styles.nameCakeWrapper}>
          {_renderField('Name', <span>{_formatName(props.student.first_name, props.student.middle_name, props.student.last_name)} {isLessThan18YearsAgo(props.student.birthday) && <CakeIcon style={styles.under18Icon} />}</span>, (previousNames.length === 0 ? null : _renderPreviousInfo(previousNames, 'names')))}
        </span>
        {_renderField('Email',
          <span>{props.student.email} {props.student.email_confirmed !== undefined && (props.student.email_confirmed ? <span title='Email verified'><CheckIcon color={Globals.colors.green} /></span> : <span title='Email not verified'><HighlightOffIcon color={Globals.colors.error} /></span>)}</span>, (previousEmails.length === 0 ? null : _renderPreviousInfo(previousEmails, 'emails')))}
        {_renderField('Phone', getFormattedPhone(props.student.phone), (previousPhoneNumbers.length === 0 ? null : _renderPreviousInfo(previousPhoneNumbers, 'phones')))}
        {_showMoreDetails &&
          <>
            {_renderField('Street address', _formatAddress(props.student.line_1, props.student.line_2, props.student.city, props.student.state, props.student.zip_code, props.student.country), (previousAddresses.length === 0 ? null : _renderPreviousInfo(previousAddresses, 'addresses')))}
            {_renderField('Birthday', moment.utc(props.student.birthday).format(PRETTY_DATE_FORMAT))}
            <Tooltip title={moment(props.student.created_at).format('LT')}>
              {_renderField('Account creation date', moment(props.student.created_at).format(PRETTY_DATE_FORMAT))}
            </Tooltip>
            <Tooltip title={moment(props.student.last_logged_date).format('LT')}>
              {_renderField('Most recent log in date', moment(props.student.last_logged_date).format(PRETTY_DATE_FORMAT))}{/* @TODO: add time too */}
            </Tooltip>
            {_renderField('School of intent', _formatSchoolOfIntent(props.student.school, props.student.school_city, props.student.school_state, props.student.program), previousSchoolOfIntent.length === 0 ? null : _renderPreviousInfo(previousSchoolOfIntent, 'School of Intent'))}
            {_renderField('Student ID', props.student.id)}
            {_renderField('Canvas ID', <a target='_blank' rel='noopener noreferrer' href={`${Globals.canvasUrl}/accounts/1/users/${props.student.canvas_id}`}>{props.student.canvas_id}</a>)}
            {_renderField('Infusionsoft ID', <a target='_blank' rel='noopener noreferrer' href={`https://zi166.infusionsoft.com/app/searchResults/searchResults?searchTerm=${props.student.email}`}>Go to Infusionsoft</a>)}
            {_renderField('Notes', (previousNotes.length === 0 ? null : _renderPreviousInfo(previousNotes, 'notes')))}
            {!!admin.perms[DEVELOPER] && _renderField('Cognito ID', <a target='_blank' rel='noopener noreferrer' href={`https://console.aws.amazon.com/cognito/users/?region=us-east-1#/pool/us-east-1_t1KZiFDjT/users/${props.student.cognito_id}`}>{props.student.cognito_id}</a>)}
          </>
        }
        <div style={styles.detailsButtonWrapper}>
          <Button
            onClick={() => setShowMoreDetails(!_showMoreDetails)}
            color='secondary'
          >
            {_showMoreDetails ? 'Less' : 'More'}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>{_renderStudentInfo()}</div>
  );
};

StudentInformationDetails.propTypes = {
  student: PropTypes.object,
};

export default withRouter(StudentInformationDetails);
