/*
 * Ryan O'Dowd
 * 2021-11-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import './styles.css';
import React from 'react';

const ChristmasLights = (props) => {
  return (
    <span>
      {Array.from({length: 50}).map((e, i) => <div key={i} className='snowflake'></div>)}
      <ul className='lightrope'>
        {Array.from({length: 50}).map((e, i) => <li key={i}></li>)}
      </ul>
    </span>
  );
};

export default ChristmasLights;
