/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {
  fetchDepartments,
  fetchInstructorAssignmentRules,
  updateInstructorAssignmentRules,
} from '../../actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React from 'react';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

class InstructorAssignment extends React.Component {
  static propTypes = {
    fetchDepartments: PropTypes.func.isRequired,
    fetchInstructorAssignmentRules: PropTypes.func.isRequired,
    departments: PropTypes.array.isRequired,
    instructorAssignmentRules: PropTypes.array.isRequired,
    loadingStates: PropTypes.object.isRequired,
    updateInstructorAssignmentRules: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      year: new Date().getFullYear(),
      divideByDepartment: false,
      edit: false,
    };
  }

  componentDidMount() {
    this.props.fetchDepartments();
    this.props.fetchInstructorAssignmentRules(this.state.year);
  }

  _refreshAssignmentRules = () => {
    this.props.fetchInstructorAssignmentRules(this.state.year);
  }

  _enrollmentsTable = (instructorAssignmentRules, depId) => {
    const currentYear = Object.keys(instructorAssignmentRules[0]).includes('total_targets');
    const thisMonth = new Date().getMonth();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      .filter((month, index) => !currentYear || index <= thisMonth + 1);
    return <Table>
      <TableHead>
        <TableRow>
          <TableCell>Instructor</TableCell>
          {months.map((month) => <TableCell key={month}>{month}</TableCell>)}
          <TableCell>YTD Totals</TableCell>
          {currentYear &&
            <>
              <TableCell>Status</TableCell>
              {this.state.edit &&
                <TableCell>Update Targets</TableCell>
              }
            </>
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {instructorAssignmentRules.map((rule) => {
          let inactiveInstructor = false;
          let uncapped = false;
          const monthlyMult = rule.month_multiplier;
          let targets = rule.total_targets;
          let multipleDepartmentsWarning = false;
          if (currentYear) {
            inactiveInstructor = rule.total_targets.min === 0;
            if (this.state.divideByDepartment) {
              if (Object.keys(rule.targets_by_department).includes(depId)) {
                targets = rule.targets_by_department[depId];
              } else {
                multipleDepartmentsWarning = true;
              }
            } else if (Object.keys(rule.targets_by_department).length > 1) {
              multipleDepartmentsWarning = true;
            }
            uncapped = targets.max < 0;
          }

          return <TableRow key={`${rule.first_name} ${rule.last_name}`}>
            <TableCell>{`${rule.first_name} ${rule.last_name}`}</TableCell>
            {
              rule.totals.filter((month, index) => !currentYear || index <= thisMonth + 1)
                .map((month, index) => <TableCell key={index}>
                  {month[0]}<br/>
                  {month[3]}<br/>
                  {month[4]}<br/>
                  <strong>{month[0] + month[3] + month[4]}</strong>
                </TableCell>)
            }
            <TableCell>
              {rule.totals.reduce((a, b) => a + b[0], 0)}<br/>
              {rule.totals.reduce((a, b) => a + b[3], 0)}<br/>
              {rule.totals.reduce((a, b) => a + b[4], 0)}<br/>
              <strong>{rule.totals.reduce((a, b) => a + b[0] + b[3] + b[4], 0)}</strong>
            </TableCell>
            {currentYear &&
              <>
                <TableCell>
                  {uncapped ? 'Full-time YTD' : 'Monthly'} Total: <strong>{targets.current}</strong><br/>
                  {uncapped && <>Monthly Target: <strong>{targets.min}</strong><br/></>}
                  {uncapped ? `Full-time YTD Target: ${monthlyMult} x` : `Monthly Min:`} {targets.min} + {targets.overflow} = <strong>{targets.min * monthlyMult + targets.overflow}</strong>
                  {uncapped || <><br/>Monthly Max: {targets.max * monthlyMult} + {targets.overflow} = <strong>{targets.max * monthlyMult + targets.overflow}</strong></>}
                </TableCell>
                {this.state.edit &&
                  (inactiveInstructor
                    ? <TableCell>Auto-assignment is inactive for this instructor.</TableCell>
                    : (
                      multipleDepartmentsWarning
                        ? <TableCell style={styles.editTargetsError}>
                          This multi-department instructor has {this.state.divideByDepartment ? 'only one total target.' : 'multiple targets.'}<br/>
                          Turn {this.state.divideByDepartment ? 'off' : 'on'} Divide by Department to edit their targets.
                        </TableCell>
                        : <TableCell>
                          <TextField
                            type='number'
                            label={'Monthly Minimum'}
                            defaultValue={targets.min}
                            onBlur={(e) => {
                              const monthlyMin = Number(e.target.value);
                              if (monthlyMin >= 0 && Number.isInteger(monthlyMin) &&
                                monthlyMin !== targets.min && (uncapped || monthlyMin <= targets.max)) {
                                this.props.updateInstructorAssignmentRules(rule.id, {monthly_min: monthlyMin},
                                  this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                              }
                            }}
                          /><br/>
                          {uncapped ||
                            <TextField
                              type='number'
                              label={'Monthly Maximum'}
                              defaultValue={targets.max}
                              onBlur={(e) => {
                                const monthlyMax = Number(e.target.value);
                                if (monthlyMax >= 0 && Number.isInteger(monthlyMax) &&
                                  monthlyMax !== targets.max && monthlyMax >= targets.min) {
                                  this.props.updateInstructorAssignmentRules(rule.id, {monthly_max: monthlyMax},
                                    this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                                }
                              }}
                            />
                          }
                          <br/>
                          <TextField
                            type='number'
                            label={'Overflow Value'}
                            defaultValue={targets.overflow}
                            onBlur={(e) => {
                              const overflow = Number(e.target.value);
                              if (Number.isInteger(overflow) && overflow !== targets.overflow) {
                                this.props.updateInstructorAssignmentRules(rule.id, {overflow: overflow},
                                  this.state.divideByDepartment ? depId : null, this._refreshAssignmentRules);
                              }
                            }}
                          />
                        </TableCell>
                    )
                  )
                }
              </>
            }
          </TableRow>;
        })}
      </TableBody>
    </Table>;
  }

  render() {
    const currentYear = new Date().getFullYear();
    let year = 2010;
    const yearOptions = [];
    while (year <= currentYear) {
      yearOptions.push(year);
      year++;
    }
    const instructorsByDepartment = {};
    const departmentNames = {};
    if (Array.isArray(this.props.instructorAssignmentRules) && Array.isArray(this.props.departments)) {
      this.props.instructorAssignmentRules.sort((a, b) => (
        a.last_name + a.first_name < b.last_name + b.first_name ? -1 : 1 // sort by last, first name
      )).sort((a, b) => Object.keys(this.props.instructorAssignmentRules[0]).includes('total_targets') ? (a.total_targets.max < 0 ? -1 : 1) : 0) // sort uncapped instructors to top
        .forEach((rule) => {
          Object.keys(rule.totals_by_department).forEach((department) => {
            if (!instructorsByDepartment[department]) {
              instructorsByDepartment[department] = [];
              const departmentName = this.props.departments.find((e) => e.id === parseInt(department)).name;
              departmentNames[department] = `${departmentName[0].toUpperCase()}${departmentName.slice(1)}`;
            }
            if (this.state.divideByDepartment) {
              instructorsByDepartment[department].push({...rule, totals: rule.totals_by_department[department]});
            } else {
              instructorsByDepartment[department].push(rule);
            }
          });
        });
    }
    return (
      <div style={styles.container}>
        <div style={styles.rangeSelection}>
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    this.setState({divideByDepartment: e.target.checked});
                  }}
                />}
              label='Divide by Department'
            />
          </FormControl>
          <FormControl>
            <Select
              defaultValue={currentYear}
              onChange={(e) => {
                this.props.fetchInstructorAssignmentRules(e.target.value);
                this.setState({year: e.target.value});
              }}
            >
              {yearOptions.map((year) => <MenuItem key={year} value={year}>{year}</MenuItem>)}
            </Select>
            <FormHelperText>Select year</FormHelperText>
          </FormControl>
          <Button
            style={styles.button}
            variant='contained'
            color='primary'
            onClick={() => this.setState({edit: true})}
          >
            Edit Targets
          </Button>
        </div>
        {'instructorAssignmentRules' in this.props && Array.isArray(this.props.instructorAssignmentRules) &&
          'departments' in this.props && Array.isArray(this.props.departments) &&
          <>
            {Object.keys(departmentNames).sort((a, b) => departmentNames[a] < departmentNames[b] ? -1 : 1)
              .map((departmentId) => {
                return <Accordion key={departmentId} style={styles.panel}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <h2 style={styles.graphTitle}>{departmentNames[departmentId]}</h2>
                  </AccordionSummary>
                  <AccordionDetails style={styles.panelDetails}>
                    {this._enrollmentsTable(instructorsByDepartment[departmentId], departmentId)}
                  </AccordionDetails>
                </Accordion>;
              })}
            <div>
              <h3>Legend:</h3>
              <p>Portage Prep<br/>3 Credits<br/>4 Credits<br/><strong>Total</strong></p>
              <p>Instructors with a hard monthly cap on enrollments are marked with a star (*).</p>
            </div>
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    departments: state.departments,
    instructorAssignmentRules: state.instructorAssignmentRules,
    loadingStates: state.loadingStates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    fetchDepartments,
    fetchInstructorAssignmentRules,
    updateInstructorAssignmentRules,
  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstructorAssignment));
