/*
 * Ryan O'Dowd
 * 2019-03-22
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const SPACE_BETWEEN_INPUTS = 8;
// @TODO: clean up

const styles = {
  container: {
    width: 350,
    margin: 'auto',
    marginTop: 96, // @TODO: dynamic to footer height
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  registerInputField: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    marginRight: SPACE_BETWEEN_INPUTS,
  },
};

export default styles;
