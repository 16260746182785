/*
 * Nick Lewyn
 * 2024-10-15
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchGradeChangeReviewReport,
} from '../../../actions';
import moment from 'moment';

const GradeChangeOpenReviewReport = () => {
  const [_fetchStatus, setFetchStatus] = useState(true);
  const [_loadingStatus, setLoadingStatus] = useState((<p>loading....</p>));
  const dispatch = useDispatch();
  const gradeChangeReport = useSelector((state) => state.gradeChangeOpenReviewReport);
  const admin = useSelector((state) => state.admin);
  const deptChairCourses = useSelector((state) => state.departmentChairCourses);
  const loadingStates = useSelector((state) => state.loadingStates);

  useEffect(() => {
    dispatch(fetchGradeChangeReviewReport());
  }, []);

  useEffect(() => {
    if (loadingStates.fetchGradeChangeReviewReport) {
      setFetchStatus(false);
    }
  }, [loadingStates]);

  useEffect(() => {
    if (!_fetchStatus && !getGradeChangeModel().length) {
      setLoadingStatus(<p>No records found at this time</p>);
    }
  }, [gradeChangeReport]);

  const getGradeChangeModel = () => {
    let courseMatches = null;
    let newArrayOfRecords = null;
    if (gradeChangeReport.length && admin.department === 'Faculty Dept Chair') {
      newArrayOfRecords = gradeChangeReport.filter((val) => {
        courseMatches = deptChairCourses.find((course) => course.code === val.course.toLowerCase().replace(/\s/g, ''));
        return !!courseMatches;
      });
      return newArrayOfRecords;
    }
    return gradeChangeReport;
  };

  return (<Paper elevation={8}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Student ID</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>Course</TableCell>
          <TableCell>Date Opened</TableCell>
          <TableCell>Opened By</TableCell>
          <TableCell>Number of Days Since Opened</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(getGradeChangeModel().length === 0 ? _loadingStatus
          : (getGradeChangeModel().map((row) => {
            return (
              <TableRow key={row.enrollment_id}>
                <>
                  <TableCell><a target='_blank' rel='noopener noreferrer' href={`/students/${row.student_id}`}>{row.student_id}</a></TableCell>
                  <TableCell>{row.student_first_name}</TableCell>
                  <TableCell>{row.student_last_name}</TableCell>
                  <TableCell>{row.course}</TableCell>
                  <TableCell>{moment(row.date_opened).local().format('L LT')}</TableCell>
                  <TableCell>{row.opened_by}</TableCell>
                  <TableCell>{row.number_of_days_opened}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </>
              </TableRow>
            );
          })))}
      </TableBody>
    </Table>
  </Paper>
  );
};

export default GradeChangeOpenReviewReport;
