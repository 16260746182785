/*
 * Ryan O'Dowd
 * 2021-03-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import React, {
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  DEVELOPER,
} from '../../Globals';
import {
  ResponsiveLine,
} from '@nivo/line';
import {
  fetchRegistrationData,
} from '../../actions';
import moment from 'moment';
import styles from './styles';
import {
  withRouter,
} from 'react-router-dom';

const CompanyDashboard = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRegistrationData());
    setInterval(dispatch(fetchRegistrationData), 5 * 60 * 1000); // refresh data automatically every 5 minutes
  }, [dispatch]);

  const admin = useSelector((state) => state.admin);

  const totalRegistrationsThisMonthSoFar = useSelector((state) => {
    const now = moment.utc();
    const year = now.format('YYYY');
    const month = now.format('MMM').toLowerCase();

    return state.portageRegistrationData[year]?.[month] + state.nabcRegistrationData[year]?.[month];
  });
  const projectedTotalRegistrationsThisMonth = useSelector((state) => {
    const now = moment.utc();
    const hoursSoFarThisMonth = ((+now.format('D') - 1) * 24) + (+now.format('H'));
    const totalHoursThisMonth = now.daysInMonth() * 24;

    return totalRegistrationsThisMonthSoFar / hoursSoFarThisMonth * totalHoursThisMonth;
  });

  const registrationData = useSelector((state) => {
    const now = moment.utc();
    const currYear = now.format('YYYY');
    const currMonth = now.format('MMM').toLowerCase();

    return [2019, 2020, 2021, 2022, 2023, 2024].map((year) => {
      return {
        id: year,
        data: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'].map((month) => {
          const y = ((state.portageRegistrationData[year]?.[month] || 0) + (state.nabcRegistrationData[year]?.[month] || 0)) || null;

          return {
            x: month,
            y: (month === currMonth && +year === +currYear) ? projectedTotalRegistrationsThisMonth : y,
          };
        }),
      };
    });
  });

  return (
    <>
      {!!admin.perms[DEVELOPER] && (
        <div style={styles.container}>
          <div style={styles.graphContainer}>
            <h3>All Registrations</h3>
            {/* @NOTE: make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered */}
            {/* @TODO: don't need to display the current month in this graph...looks pretty bad until the end of the month. (could do dotted line for projection though...)  */}
            <ResponsiveLine
              data={registrationData}
              margin={{
                top: 50, right: 110, bottom: 50, left: 60,
              }}
              xScale={{type: 'point'}}
              yScale={{
                type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false,
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Month',
                legendOffset: 36,
                legendPosition: 'middle',
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Registration count',
                legendOffset: -40,
                legendPosition: 'middle',
              }}
              pointSize={10}
              pointColor={{theme: 'background'}}
              pointBorderWidth={2}
              pointBorderColor={{from: 'serieColor'}}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: 'bottom-right',
                  direction: 'column',
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: 'left-to-right',
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: 'circle',
                  symbolBorderColor: 'rgba(0, 0, 0, .5)',
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemBackground: 'rgba(0, 0, 0, .03)',
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            />
          </div>
          <div style={styles.totals}>
            <h3>{`${moment.utc().format('MMMM')} ${moment.utc().format('YYYY')}`}</h3>
            <span>Actual: <strong>{totalRegistrationsThisMonthSoFar}</strong></span>
            <span>Projected: <strong>{Math.round(projectedTotalRegistrationsThisMonth)}</strong></span>
          </div>
        </div>
      )}
    </>
  );
};

CompanyDashboard.propTypes = {};

export default withRouter(CompanyDashboard);
