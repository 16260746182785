/*
 * Stevie Michalik
 * 2023-06-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchCourseEnrollmentData,
} from '../../actions';
import styles from './styles';

const Reporting = () => {
  const dispatch = useDispatch();
  const reportingData = useSelector((state) => (state.courseEnrollmentReport));
  const [_yearToExport, setExportYear] = useState(new Date().getFullYear());
  const [_rowsPerPage, setRowsPerPage] = useState(25);
  const [_searchTerm, setSearchTerm] = useState('');
  const [_pageNum, setPageNum] = useState(0);

  useEffect(() => {
    dispatch(fetchCourseEnrollmentData(_yearToExport));
  }, [dispatch, _yearToExport]);

  const _returnSelectorOptions = () => {
    const years = [];
    const currentYear = new Date().getFullYear();
    for (let decrementYear = currentYear; decrementYear > currentYear - 10; decrementYear--) {
      years.push(<MenuItem key={decrementYear} value={decrementYear}>{decrementYear}</MenuItem>);
    }

    return years;
  };

  const _renderCourses = () => {
    if (!Object.values(reportingData).length) {
      return (<p>loading.....</p>);
    }

    const searchTerms = _searchTerm.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));

    const totalLine = Object.values(reportingData).filter((course) => course.code === 'TOTAL')[0];
    const filteredCourses = Object.values(reportingData).filter((course) => {
      if (course.code === 'TOTAL') {
        return false;
      }
      if (!_searchTerm) {
        return true;
      }

      return searchTerms.some((groupOfSearchTerms) => {
        return groupOfSearchTerms.every((searchTerm) => {
          return Object.keys(course).some((courseAttribute) => {
            return `${course[courseAttribute]}`.toLowerCase().includes(searchTerm);
          });
        });
      });
    }).sort((a, b) => a.code.localeCompare(b.code));

    return (
      <Paper elevation={8}>
        <div style={styles.tableWrapper}>
          <Table stickyHeader size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Class</TableCell>
                <TableCell>Jan ({totalLine.jan})</TableCell>
                <TableCell>Feb ({totalLine.feb})</TableCell>
                <TableCell>Mar ({totalLine.mar})</TableCell>
                <TableCell>Q1 ({totalLine.jan + totalLine.feb + totalLine.mar})</TableCell>
                <TableCell>Apr ({totalLine.apr})</TableCell>
                <TableCell>May ({totalLine.may})</TableCell>
                <TableCell>Jun ({totalLine.jun})</TableCell>
                <TableCell>Q2 ({totalLine.apr + totalLine.may + totalLine.jun})</TableCell>
                <TableCell>Jul ({totalLine.jul})</TableCell>
                <TableCell>Aug ({totalLine.aug})</TableCell>
                <TableCell>Sep ({totalLine.sep})</TableCell>
                <TableCell>Q3 ({totalLine.jul + totalLine.aug + totalLine.sep})</TableCell>
                <TableCell>Oct ({totalLine.oct})</TableCell>
                <TableCell>Nov ({totalLine.nov})</TableCell>
                <TableCell>Dec ({totalLine.dec})</TableCell>
                <TableCell>Q4 ({totalLine.oct + totalLine.nov + totalLine.dec})</TableCell>
                <TableCell>Total ({totalLine.jan + totalLine.feb + totalLine.mar + totalLine.apr + totalLine.may + totalLine.jun + totalLine.jul + totalLine.aug + totalLine.sep + totalLine.oct + totalLine.nov + totalLine.dec})</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCourses.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((data) => {
                return (
                  <TableRow key={data.code} hover={true}>
                    <TableCell>{data.code}</TableCell>
                    <TableCell>{!data.jan ? '-' : data.jan}</TableCell>
                    <TableCell>{!data.feb ? '-' : data.feb}</TableCell>
                    <TableCell>{!data.mar ? '-' : data.mar}</TableCell>
                    <TableCell>{(data.jan + data.feb + data.mar) === 0 ? '-' : data.jan + data.feb + data.mar}</TableCell>
                    <TableCell>{!data.apr ? '-' : data.apr}</TableCell>
                    <TableCell>{!data.may ? '-' : data.may}</TableCell>
                    <TableCell>{!data.jun ? '-' : data.jun}</TableCell>
                    <TableCell>{(data.apr + data.may + data.jun) === 0 ? '-' : data.apr + data.may + data.jun}</TableCell>
                    <TableCell>{!data.jul ? '-' : data.jul}</TableCell>
                    <TableCell>{!data.aug ? '-' : data.aug}</TableCell>
                    <TableCell>{!data.sep ? '-' : data.sep}</TableCell>
                    <TableCell>{(data.jul + data.aug + data.sep) === 0 ? '-' : data.jul + data.aug + data.sep}</TableCell>
                    <TableCell>{!data.oct ? '-' : data.oct}</TableCell>
                    <TableCell>{!data.nov ? '-' : data.nov}</TableCell>
                    <TableCell>{!data.dec ? '-' : data.dec}</TableCell>
                    <TableCell>{(data.oct + data.nov + data.dec) === 0 ? '-' : data.oct + data.nov + data.dec}</TableCell>
                    <TableCell>{(data.jan + data.feb + data.mar + data.apr + data.may + data.jun + data.jul + data.aug + data.sep + data.oct + data.nov + data.dec) === 0 ? '-' : data.jan + data.feb + data.mar + data.apr + data.may + data.jun + data.jul + data.aug + data.sep + data.oct + data.nov + data.dec}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component='div'
            count={filteredCourses.length}
            rowsPerPage={_rowsPerPage}
            page={_pageNum}
            backIconButtonProps={{'aria-label': 'Previous Page'}}
            nextIconButtonProps={{'aria-label': 'Next Page'}}
            onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
            onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
          />
        </div>
      </Paper>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div style={styles.headerText}>
          <h2><strong>Enrollments by Course</strong></h2>
          <p>Shows number of enrollments in each offered course for the selected year</p>
          <p>The number in parenthesis for each month shows the total enrollments for each month for all courses offered</p>
          <p>Please keep in mind that the numbers shown are not exact, and are not supposed to be used for any reporting where exactness is required</p>
          <p>The total for each course does not include students who have not yet received an instructor, or any enrollment with a final grade of NG</p>
        </div>
        <div style={styles.yearContainer}>
          <TextField
            style={{marginRight: 10}}
            label='Search'
            variant='outlined'
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPageNum(0);
            }}
          />
          <Select
            style={styles.selectOptions}
            defaultValue={_yearToExport}
            id='exception'
            label='Type'
            onChange={(e) => {
              setExportYear(e.target.value);
            }}
          >
            {_returnSelectorOptions()}
          </Select>
        </div>
      </div>
      {_renderCourses()}
    </div>
  );
};

export default Reporting;
