/*
 * Andrew Moskal
 * 2024-10-11
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Paper,
  TextField,
} from '@material-ui/core';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Autocomplete,
} from '@mui/material';
import ResetUserPassword from '../Shared/ResetPassword';
import {
  fetchInstructors,
} from '../../actions';
import styles from './styles.js';

const Faculty = () => {
  const instructors = useSelector((state) => Object.values(state.instructors).filter((instructor) => instructor.disabled === 0)).sort((i1, i2) => {
    return (i1.first_name > i2.first_name) ? 1 : (i1.first_name < i2.first_name) ? -1 : 0;
  });
  const [_instructor, setInstructor] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstructors());
  }, [dispatch]);

  return (
    <div style={styles.container}>
      <Paper style={styles.paper} elevation={8}>
        <div>
          <h2>Update Faculty</h2>
          <p>{"Reset a faculty member's password and in the future will grant the ability to Enable/Disable a faculty member."}</p>
        </div>
        <div style={styles.selectContainer}>
          <Autocomplete
            style={{width: 250}}
            id='instructorSelect'
            options={instructors}
            getOptionLabel={ (instructor) => `${instructor.first_name} ${instructor.last_name}`}
            onChange={(e, newValue, reason) => {
              switch (reason) {
              case 'selectOption':
                setInstructor(newValue);
                break;
              default:
                setInstructor({});
                break;
              }
            }}
            renderOption={(props, instructor) => (
              <li {...props}>
                {instructor.first_name} {instructor.last_name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Choose a faculty member'
              />
            )}
          />
        </div>
        <div style={styles.selectContainer}>
          {(_instructor.disabled === 0) &&
          <ResetUserPassword userId={_instructor.id} firstName={_instructor.first_name} lastName={_instructor.last_name} />}
        </div>
      </Paper>
    </div>
  );
};

export default Faculty;
