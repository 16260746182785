/*
 * Ryan O'Dowd
 * 2019-01-15
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Auth,
} from 'aws-amplify';
import {
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import {
  logout,
} from '../../actions';
import {
  withRouter,
} from 'react-router-dom';

const Account = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);

  if (!admin) {
    return null;
  }

  return (
    <div>
      <h3>Account</h3>
      <p>{`You currently are logged in as ${admin.first_name} ${admin.last_name} (${admin.email}).`}</p>
      <Button
        variant='contained'
        color='primary'
        onClick={() => {
          dispatch(logout());
          Auth.signOut();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

Account.propTypes = {
  admin: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export default withRouter(Account);
