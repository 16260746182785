/*
 * Nathaniel Padgett
 * 2019-01-09
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const styles = {
  schoolName: {
    display: 'flex',
    marginBottom: 2,
    fontSize: 20,
    whiteSpace: 'pre-wrap',
  },
  schoolCityAndState: {
    display: 'flex',
    marginBottom: 4,
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
  studentLinks: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 18,
  },
  studentLink: {
    fontSize: 10,
    marginRight: 4,
  },
  chip: {
    width: '100%',
    margin: 2,
    marginBottom: 4,
    color: Globals.colors.light,
    borderColor: Globals.colors.light,
    backgroundColor: Globals.colors.dark,
  },
  chipTitle: {
    display: 'block',
    fontSize: 12,
    lineHeight: '12px',
  },
  jwScore: {
    color: Globals.colors.darkGray,
  },
  textField: {
    flexGrow: 1,
    paddingRight: 5,
  },
  formRow: {
    display: 'flex',
    alignItems: 'baseline',
  },
  stateSelectField: {
    width: 60,
  },
  maxWidth: {
    maxWidth: 400,
  },
};

export default styles;
